
import Vue from "vue";

import Container from "@/components/Container.vue";
import DomainLookupForm from "@/components/DomainLookupForm.vue";
import Hero from "@/components/Hero.vue";

export default Vue.extend({
  name: "About",
  components: {
    Container,
    DomainLookupForm,
    Hero,
  },
});
