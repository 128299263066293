
import Vue from "vue";
import { ValidationProvider } from "vee-validate";

import { Button, Form, FormError, Input } from "@num/component-library";

export default Vue.extend({
  name: "DomainLookupForm",
  components: {
    Button,
    Form,
    FormError,
    Input,
    ValidationProvider,
  },
  data() {
    return {
      input: "",
    };
  },
  methods: {
    submitForm() {
      this.$router.push({
        name: "company",
        params: {
          companyDomain: this.input.toLowerCase(),
        },
      });
    },
  },
});
